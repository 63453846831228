import React, { useState, useEffect } from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import background from './images/background.jpg'; // Importa l'immagine di sfondo
import profilePic from './images/profile-pic.jpg'; // Importa l'immagine del profilo
import landscapePic from './images/landscape-pic.jpg'; // Importa l'immagine di copertina
import whatsappIcon from './images/whatsapp-icon.svg'; // Importa l'icona di WhatsApp
import contactIcon from './images/contact-info.svg'; // Importa l'icona per aggiungere contatto
import manualIcon from './images/file-earmark-text.svg'; 
import emailIcon from './images/email-icon.svg'; // Importa l'icona per inviare email
import telephoneIcon from './images/telephone-icon.svg'; // Importa l'icona per il telefono

function handleAddContact(link_vCard) {
  window.open(link_vCard, '_blank');
}

function openLink(shared_link) {
  window.open(shared_link, '_blank');
}

function openWhatsApp(mobile_number) {
  window.open(`https://wa.me/${mobile_number}`, '_blank');
}

function sendEmail(email) {
  window.location.href = `mailto:${email}`;
}

function callPhone(mobile_number) {
  window.location.href = `tel:${mobile_number}`;
}

function App() {
  const [code, setCode] = useState('');
  const [details, setDetails] = useState(null);
  const [error, setError] = useState('');

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`/eurogas/${code}`);
      if (response.ok) {
        const data = await response.json();
        setDetails(data);
        setError('');
      } else {
        setError('Codice non trovato');
        setDetails(null);
      }
    } catch (err) {
      setError('Errore del server');
      setDetails(null);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="container-fluid bg-dark text-light vh-100">
      {!details ? (
        <div className="d-flex flex-column align-items-center justify-content-center position-relative">
          <img src={landscapePic} alt="Landscape Picture" className="landscape-img mb-4" />
          <h1 className="mb-3">Benvenuto! Inserisci il codice cliente</h1>
          <input
            type="text"
            value={code}
            onChange={handleCodeChange}
            placeholder="Inserisci il codice"
            pattern="[0-9]*"
            inputMode="numeric"
            className="form-control mb-3"
            style={{ width: '200px' }}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSubmit} className="btn btn-primary">Invia</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      ) : (
        <div>
          <img src={background} alt="Background" className="position-fixed top-0 start-0 w-100 h-100 blurred" />
          <div className="content position-relative text-center d-flex flex-column align-items-center justify-content-center">
            <div className="landscape-img-container mb-4">
              <img src={landscapePic} alt="Landscape Picture" className="landscape-img" />
            </div>
            <div>
              <img src={profilePic} alt="Profile Picture" className="rounded-circle border border-light mb-4" width="150" height="150" />
              <h1 className="mb-3" style={{ fontSize: '24px' }}>{details.name}</h1>
              <p className="mb-4" style={{ fontSize: '18px' }}>{details.società}</p>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
              <button className="btn btn-outline-light d-flex align-items-center justify-content-center" onClick={() => sendEmail(details.email_lavorativa)}>
                <img src={emailIcon} alt="Email Lavorativa" className="me-2" style={{ width: '24px', height: '24px' }} />
                MV - Mail
              </button>
              <button className="btn btn-outline-light d-flex align-items-center justify-content-center" onClick={() => openLink(details.shared_link)}>
                <img src={manualIcon} alt="Apri manuale caldaia" className="me-2" style={{ width: '24px', height: '24px' }} />
                Link al progetto
              </button>
              {details.vip === 1 ? (
                <>
                  <button className="btn btn-outline-light d-flex align-items-center justify-content-center" onClick={() => sendEmail(details.email_personale)}>
                    <img src={emailIcon} alt="Email Personale" className="me-2" style={{ width: '24px', height: '24px' }} />
                    Marco - Mail
                  </button>
                  <button className="btn btn-outline-light d-flex align-items-center justify-content-center" onClick={() => callPhone(details.mobile_number)}>
                    <img src={telephoneIcon} alt="Telefono" className="me-2" style={{ width: '24px', height: '24px' }} />
                    Chiamami
                  </button>
                  <button className="btn btn-outline-light d-flex align-items-center justify-content-center" onClick={() => openWhatsApp(details.mobile_number)}>
                    <img src={whatsappIcon} alt="WhatsApp" className="me-2" style={{ width: '24px', height: '24px' }} />
                    WhatsApp
                  </button>
                </>
              ) : (
                <>
                <button className="btn btn-outline-light" onClick={() => alert('Iscriviti a VIP!')}>
                  Iscriviti a VIP
                </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

